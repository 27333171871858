import { TIMESTAMP_MILLISECONDS } from 'constants/general';

export enum VisibilityModeType {
  Default = 'DEFAULT',
  Visible = 'VISIBLE',
  Private = 'PRIVATE',
  User = 'USER',
  Unknown = 'UNKNOWN',
}

export enum DateFormats {
  defaultDate = 'MM/dd/yyyy',
  longDate = 'MMM dd, yyyy',
  fullDateFormat = 'MMM dd, yyyy, HH:mm',
}

export enum PointCurrency {
  Invite = 'INVITE',
  Like = 'LIKE',
  Min = 'MIN',
  None = 'NONE',
  Point = 'POINT',
  Rating = 'RATING',
  Repu = 'REPU',
  Star = 'STAR',
  Star2 = 'STAR2',
  Star3 = 'STAR3',
  Unknown = 'UNKNOWN',
  Systkn = 'SYSTKN',
}

export enum RewardReceiver {
  Participant = 'PARTICIPANT',
  Winner = 'WINNER'
}

export enum CurrencyTypes {
  NONE = 'NONE',
  UNKNOWN = 'UNKNOWN',
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  RON = 'RON',
  MDL = 'MDL',
  GBP = 'GBP',
  PLN = 'PLN',
  GEL = 'GEL',
  ARS = 'ARS',
  AED = 'AED',
}

export enum ApiStatus {
  idle = 'idle',
  loading = 'loading',
  failed = 'failed',
}

export enum StoryDuration {
  default = 7 * TIMESTAMP_MILLISECONDS,
  interactive = TIMESTAMP_MILLISECONDS,
}

export enum KeyCategory {
  Urgent = 'BN',
  Important = 'GN',
  Normal = 'NN',
  Unimporant = 'SN',
  Garbage = 'WN',
}

export enum StreamCodes {
  Regular = 'GG',
  Custom = 'HH',
  InFocus = 'TT',
  Stream = 'STREAM',
}

export enum CtrlTypes {
  Shown = 'A',
}

export enum SponsorState {
  none = 'NONE',
  sent = 'SENT',
  rejected = 'REJECTED',
  accepted = 'ACCEPTED',
  notAssigned = 'NOT_ASSIGNED',
  unknown = 'UNKNOWN',
}

export enum CreditCardTypes {
  Amex = 'AMEX',
  AmericanExpress = 'AMERICANEXPRESS',
  American_Express = 'AMERICAN_EXPRESS',
  Mc = 'MC',
  MasterCard = 'MASTERCARD',
  Master_Card = 'MASTER_CARD',
  Visa = 'VISA',
  Generic = 'GENERIC',
  Other = 'OTHER',
}

export enum AttachmentDisplayStatus {
  Inline = 'INLINE',
  Attach = 'ATTACH',
}

export enum AttachmentMeaning {
  Product = 'PRODUCT',
  Promotion = 'PROMOTION',
  Other = 'OTHER',
  OtherDoc = 'OTHER_DOC',
  BadgeIcon = 'badgeIcon',
  BadgeImage = 'badgeImage',
}

export enum AttachmentType {
  Image = 'IMAGE',
  Gif = 'GIF',
  Video = 'VIDEO',
  Audio = 'AUDIO',
  Pdf = 'PDF',
  Youtube = 'YOUTUBE',
  Website = 'WEBSITE',
  File = 'FILE',
  Other = 'OTHER',
}

export enum AttachmentVisibility {
  All = 'ALL',
  Participants = 'PARTICIPANTS',
  Creator = 'CREATOR',
  None = 'NONE',
}

export enum DisplayStyle {
  Inline = 'INLINE',
  Attach = 'ATTACH',
}

export enum BountyType {
  Album = 'ALBUM',
  Autosuggestion = 'AUTOSUGGESTION',
  BadgeCollection = 'BADGE_COLLECTION',
  Banner = 'BANNER',
  Bundle = 'BUNDLE',
  Checklist = 'CHECKLIST',
  Classified = 'CLASSIFIED',
  Funding = 'FUNDING',
  Job = 'JOB',
  LiveStream = 'LIVE_STREAM',
  Lottery = 'LOTTERY',
  Match = 'MATCH',
  MCQ = 'MCQ',
  News = 'NEWS',
  None = 'NONE',
  Order = 'ORDER',
  PrivateListing = 'PRIVATE_LISTING',
  Product = 'PRODUCT',
  Promotion = 'PROMOTION',
  Quest = 'QUEST',
  Question = 'QUESTION',
  RealEstateCustomer = 'REALESTATE_CUSTOMER',
  RealEstateRecommendation = 'REALESTATE_RECOMMENDATION',
  Score = 'SCORE',
  Survey = 'SURVEY',
  Poll = 'POLL',
  TalentRecommendation = 'TALENT_RECOMMENDATION',
  TalentSearch = 'TALENT_SEARCH',
  Thread = 'THREAD',
  TMOB_Challenge = 'TMOB_CHALLENGE',
  TMOB_Coaching = 'TMOB_COACHING',
  Trivia = 'TRIVIA',
  Deal = 'DEAL',
  Unknown = 'UNKNOWN',
  Post = 'POST',
  Distribution = 'DISTRIBUTION',
  Campaign = 'CAMPAIGN',
}

export enum BountyState {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Closed = 'CLOSED',
  Declined = 'DECLINED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  New = 'NEW',
  None = 'NONE',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  Refused = 'REFUSED',
  Rejected = 'REJECTED',
  Retracted = 'RETRACTED',
  Sealed = 'SEALED',
  Unknown = 'UNKNOWN',
}

export enum BucketingPolicy {
  NONE = 'NONE',
  COMMON = 'COMMON',
  BY_TYPE = 'BY_TYPE',
  BY_COMPANY = 'BY_COMPANY',
  BY_COMPANY_AND_TYPE = 'BY_COMPANY_AND_TYPE',
}

export enum SummaryProgressState {
  None = 'NONE',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Unknown = 'UNKNOWN',
}

export enum BountyThemeSchema {
  NEUTRAL = 'NEUTRAL',
  JOYFUL = 'JOYFUL',
  SERENE = 'SERENE',
  ENERGETIC = 'ENERGETIC',
  MYSTERIOUS = 'MYSTERIOUS',
  ROMANTIC = 'ROMANTIC',
  PROFESSIONAL = 'PROFESSIONAL',
  NOSTALGIC = 'NOSTALGIC',
  DRAMATIC = 'DRAMATIC',
  NATURAL = 'NATURAL',
  FUTURISTIC = 'FUTURISTIC'
}

export enum CompanySentsFolder {
  Autosuggestion = 'AUTOSUGGESTION_FOLDER',
  Listings = 'listings',
  Products = 'products',
  Orders = 'orders',
  BadgeCollections = 'badgeCollections',
  Promotions = 'promotions',
  Jobs = 'jobs',
  News = 'news',
  Banners = 'banners',
  Matches = 'matches',
  Currencies = 'currencies',
}

export enum BountyResponseState {
  None = 'NONE',
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Acked = 'ACKED',
  Progressing = 'PROGRESSING',
  Accepted = 'ACCEPTED',
  Refused = 'REFUSED',
  Paying = 'PAYING',
  Paid = 'PAID',
  Retracted = 'RETRACTED',
  Rejected = 'REJECTED',
  Deleted = 'DELETED',
  New = 'NEW',
  Declined = 'DECLINED',
  Unavailable = 'UNAVAILABLE',
}

export enum ResponseType {
  None = 'NONE',
  Recommendation = 'RECOMMENDATION',
  Note = 'NOTE',
  Choice = 'CHOICE',
  Order = 'ORDER',
  TimelineEvent = 'TIMELINE_EVENT',
}

export enum IdentityModeType {
  ForcedAnon = 'FORCED_ANON',
  ForcedReal = 'FORCED_REAL',
  Anon = 'ANON',
  Real = 'REAL',
}

export enum IdentityType {
  Anon = 'ANON',
  Real = 'REAL',
  Default = 'DEFAULT',
}

export enum EntityType {
  Bounty = 'bounty',
  Response = 'response',
}

export enum OutboundResponseRefType {
  sentItems = 'SENT_ITEMS',
}

export enum CustomCurrencyStatus {
  Active = 'ACTIVE',
  OnCancelation = 'ON_CANCELATION',
  Expired = 'EXPIRED',
}

export enum ReportReasonTypes {
  hate = 'HATE',
  harassment = 'HARASSMENT',
  drug = 'DRUG',
  violence = 'VIOLENCE',
  dangerOrg = 'DANGER_ORG',
  malicious = 'MALICIOUS',
  misInformation = 'MISINFORMATION',
  privateInfo = 'PRIVATE',
  sexual = 'SEXUAL',
  spam = 'SPAM',
  offTopic = 'OFF_TOPIC',
  illegal = 'ILLEGAL',
  copyright = 'COPYRIGHT'
}

export enum CommentType {
  Bounty = 'BOUNTY',
  Response = 'RESPONSE',
  User = 'USER',
}

export enum SortBy {
  CreatorUpdatedAt = 'creatorUpdatedAtSortKey',
  TypeCreatedAt = 'typeCreatedAtSortKey',
  TypePopularity = 'typePopularitySortKey',
  TypeDiscussed = 'typeDiscussedSortKey',
  ListCreatedAt = 'listCreatedAtSortKey',
  ListPopularity = 'listPopularitySortKey',
  ListDiscussed = 'listDiscussedSortKey',
  StreamCreatedAt = 'streamCreatedAtSortKey',
  StreamPopularity = 'streamPopularitySortKey',
  StreamDiscussed = 'streamDiscussedSortKey',
  BountyCreated = 'bountyCreatedAtSortKey',
  BountyPopularity = 'bountyPopularitySortKey',
  BountyDiscussed = 'bountyDiscussedSortKey',
}

export enum AllowMode {
  Default = 'DEFAULT',
  Always = 'ALWAYS',
  Never = 'NEVER',
  May = 'MAY',
  Should = 'SHOULD',
}

export enum ShowInType {
  Home = 'HOME',
  Explicit = 'EXPLICIT',
  Unknown = 'UNKNOWN',
}

export enum StreamListKind {
  Inbox = 'INBOX',
  Refs = 'REFS',
  Bucket = 'BUCKET',
  FilterByBountyType = 'FILTER_BY_BOUNTY_TYPE',
  RestCall = 'REST_CALL',
  Special = 'SPECIAL',
  WebView = 'WEBVIEW',
  Multi = 'MULTI',
  Stats = 'STATS',
  Unknown = 'UNKNOWN',
  Target = 'TARGET',
}

export enum StreamListType {
  None = 'NONE',
  Stream = 'STREAM',
  EdgeCoachings = 'EDGE_COACHINGS',
  EdgeInFocus = 'EDGE_IN_FOCUS',
  EdgeChallenges = 'EDGE_CHALLENGES',
  BuySell = 'BUY_SELL',
  Market = 'MARKET',
  Academia = 'ACADEMIA',
  Custom = 'CUSTOM',
  WebView = 'WEBVIEW',
  Favorites = 'FAVORITES',
  Autosuggestions = 'AUTOSUGGESTIONS',
  Jobs = 'JOBS',
  Applications = 'APPLICATIONS',
  MatchDetails = 'MATCH_DETAILS',
}

export enum Currency {
  None = 'NONE',
  Unknown = 'UNKNOWN',
  ALL = 'ALL',
  USD = 'USD',
  CAD = 'CAD',
  EUR = 'EUR',
  RON = 'RON',
  MDL = 'MDL',
  GBP = 'GBP',
  PLN = 'PLN',
  GEL = 'GEL',
  ARS = 'ARS',
  AED = 'AED',
}

export enum PhoneStatus {
  Verified = 'VERIFIED',
  Unverified = 'UNVERIFIED',
}

export enum UserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  User = 'USER',
  Operator = 'OPERATOR',
  CampaignAdmin = 'CAMPAIGN_ADMIN',
}

export enum ProductFlavours {
  Rebounty = 'rebounty',
  Backstage = 'youkno',
  Belong = 'belong',
  Stash = 'stash',
  Alleaves = 'alleaves',
}

export enum AccessMode {
  PIN = 'PIN',
}

export enum PermissionType {
  Hide = 'HIDE',
  ReadOnly = 'READ_ONLY',
  WriteOnly = 'WRITE_ONLY',
  ReadWrite = 'READ_WRITE',
  Unknown = 'UNKNOWN',
}

export enum UserSocial {
  Follow = 'follow',
  Endorse = 'endorse',
}

export enum MarketMode {
  UserInternal = 'USER_INTERNAL',
}

export enum UserReputation {
  None = 'NONE',
  VIP = 'VIP',
  Pro = 'PRO',
  Custom = 'CUSTOM',
  Verified = 'VERIFIED',
}

export enum StoryType {
  Bounty = 'bounty',
  Leaderboard = 'leaderboard',
  ClosingSlide = 'closingSlide',
}

export enum LoginMethod {
  Email = 'Email',
  Google = 'Google',
  Apple = 'Apple',
}

export enum AuthProviderId {
  Apple = 'apple.com',
  Google = 'google.com',
}

export enum MergeStatus {
  Merge = 'MERGED',
  AlreadyMerged = 'ALREADY_MERGED',
}

export enum WinnerSelectionMethod {
  Manual = 'MANUAL',
  LikesThreshold = 'LIKES_THRESHOLD',
  LikesWeightedRandom = 'LIKES_WEIGHTED_RANDOM',
  Unknown = 'UNKNOWN',
}

export enum RewardType {
  Money = 'MONEY',
  Points = 'POINTS',
  Voucher = 'VOUCHER',
  None = 'NONE',
  Badge = 'BADGE',
  Both = 'BOTH',
  Either = 'EITHER'
}

export enum ConfirmationType {
  None = 'NONE',
  HideLetsGoDialog = 'HIDE_LETS_GO_DIALOG',
  HideChallengeRepliesHint = 'HIDE_CHALLENGE_REPLIES_HINT',
  HideHowItWorksCampaignHint = 'HIDE_HOW_IT_WORKS_CAMPAIGN_HINT',
  HideHowItWorksPollHint = 'HIDE_HOW_IT_WORKS_POLL_HINT',
  HideHowItWorksQuizHint = 'HIDE_HOW_IT_WORKS_QUIZ_HINT',
  HideHowItWorksTriviaHint = 'HIDE_HOW_IT_WORKS_TRIVIA_HINT',
  HideHowItWorksChallengeHint = 'HIDE_HOW_IT_WORKS_CHALLENGE_HINT',
  HideHowItWorksLotteryHint = 'HIDE_HOW_IT_WORKS_LOTTERY_HINT',
  HideHowItWorksFundingHint = 'HIDE_HOW_IT_WORKS_FUNDING_HINT',
  Unknown = 'UNKNOWN',
}

export enum ManifestType {
  None = 'NONE',
  M3U8 = 'M3U8',
  MPD = 'MPD',
  Unknown = 'UNKNOWN',
}
