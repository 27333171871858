import { Box, Typography } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import EmptyLeaderboard from 'components/EmptyLeaderboard';
import LoadingStory from 'components/LoadingStory';
import ParticipantRank from 'components/ParticipantRank';
import { FbUserContext } from 'context/FbUserContext';
import { initialClaimTokensTimer, LeaderboardContext, LeaderboardDispatchContext } from 'context/LeaderboardContext';

import { getBountyLeaderboard } from 'services/Bounty';

import ArrowBack from 'assets/icons/ArrowBack';
import { SERIES_DETAILS_ROUTE } from 'constants/clientRoutes';
import { UserPosition } from 'models/leaderboard.interface';
import { handleApiErrors } from 'utils/error';
import { formatRoute } from 'utils/formatters';

import { Root, RoundedButton, Content, Separator } from './index.styled';

const SeriesRankings = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { campaignId = '' } = useParams();
  const { counter } = useContext(LeaderboardContext);
  const { startCheck } = useContext(LeaderboardDispatchContext);
  const { fbUser } = useContext(FbUserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [positions, setPositions] = useState<UserPosition[]>([]);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const triggerClaimTokensTimer = useMemo(() => {
    if (fbUser?.isAnonymous) {
      return positions?.some((position: UserPosition) => (
        position?.user?.id === fbUser?.uid
      ));
    }

    return false;
  }, [positions, fbUser]);

  useEffect(() => {
    if (campaignId) {
      fetchLeaderboard();
    }
  }, [campaignId]);

  useEffect(() => {
    if (triggerClaimTokensTimer && counter === initialClaimTokensTimer) {
      startCheck();
    }
  }, [triggerClaimTokensTimer, counter]);

  const fetchLeaderboard = async () => {
    setIsLoading(true);

    try {
      const queryParams = {
        // eslint-disable-next-line camelcase
        exclude_anon: true,
        // eslint-disable-next-line camelcase
        include_next_activities: true,
      };
      const response = await getBountyLeaderboard(campaignId, queryParams);
      setPositions(response?.data?.positions);
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    navigate(formatRoute(SERIES_DETAILS_ROUTE, { campaignId }));
  };

  if (isLoading) {
    return <LoadingStory />;
  }

  return (
    <Root>
      <RoundedButton
        variant="outlinedDark"
        onClick={handleBack}
        startIcon={<ArrowBack />}
      >
        <Typography variant="body2">
          {intl.formatMessage({ id: 'button.back' })}
        </Typography>
      </RoundedButton>
      <Typography fontSize="28px" lineHeight="33px" fontWeight={700} mt={4.5} mb={3} ml={2}>
        {intl.formatMessage({ id: 'label.seriesRankings' })}
      </Typography>
      <Content>
        {positions
          ? (
            <Box
              display="flex"
              flexDirection="column"
              mt={2}
              className="hidden-scroll"
              maxHeight="679px"
            >
              {positions?.map((userPosition, index) => {
                const isLoggedUser = fbUser?.uid === userPosition?.user?.id;
                return (
                  <div ref={isLoggedUser ? targetRef : null} key={userPosition?.user?.id}>
                    <ParticipantRank
                      userPosition={userPosition}
                      isActive={isLoggedUser}
                      index={index + 1}
                    />
                    {index + 1 !== positions?.length && <Separator />}
                  </div>
                );
              })}
            </Box>
          ) : (
            <Box p={2}>
              <EmptyLeaderboard onClick={fetchLeaderboard} />
            </Box>
          )}
      </Content>
    </Root>
  );
};

export default SeriesRankings;
