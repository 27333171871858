import { Box } from '@mui/material';
import Lottie from 'lottie-react';

import { Skeleton } from 'components/LoadingStory/index.styled';

import loadingAnimation from 'assets/animations/campaignsLoadingWhite.json';

const LoadingStorySkeleton = () => (
  <Skeleton>
    <Box width="50%">
      <Lottie animationData={loadingAnimation} loop />
    </Box>
  </Skeleton>
);

export default LoadingStorySkeleton;
