import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import ExternalBrowser from 'containers/Authentication/ExternalBrowser';
import LoginMethods from 'containers/Authentication/LoginMethods';
import LoginWithEmail from 'containers/Authentication/LoginWithEmail';

import { useAuth } from 'hooks/useAuth';
import { useAppDispatch } from 'hooks/useRedux';
import { isSubmittingSelector, loginMethodSelector } from 'store/signinInvitation/selectors';
import { setLoginMethod } from 'store/signinInvitation/signinInvitationSlice';

import { AuthProviderId, LoginMethod } from 'constants/enums';
import { isWebView } from 'utils/env';

interface AuthenticationProps {
  onSuccess?: (shouldRefreshContent?: boolean) => void;
}

const Authentication:FC<AuthenticationProps> = ({ onSuccess }) => {
  const [showExternal, setShowExternal] = useState(false);
  const dispatch = useAppDispatch();
  const { signInUserWithProvider } = useAuth(onSuccess);
  const method = useSelector(loginMethodSelector);
  const isSubmitting = useSelector(isSubmittingSelector);

  const chooseLoginMethod = (loginMethod: LoginMethod) => {
    if (loginMethod !== LoginMethod.Email && isWebView()) {
      setShowExternal(true);
      return;
    }

    dispatch(setLoginMethod(loginMethod));

    if (loginMethod !== LoginMethod.Email) {
      signInUserWithProvider(AuthProviderId[loginMethod]);
    }
  };

  if (showExternal) {
    return <ExternalBrowser />;
  }

  return (
    <Box className="hidden-scroll" height="100%" width="100%">
      {method === LoginMethod.Email
        ? <LoginWithEmail onSuccess={onSuccess} />
        : <LoginMethods chooseLoginMethod={chooseLoginMethod} isSubmitting={isSubmitting} />}
    </Box>
  );
};

export default Authentication;
