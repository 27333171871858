import {
  alpha,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ChangeEvent, FC, useMemo, useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import ForgotPassword from 'containers/Authentication/ForgotPassword';
import { UnderlineText } from 'containers/Authentication/index.styled';
import Navigation from 'containers/Authentication/Navigation';
import VerifyEmail from 'containers/Authentication/VerifyEmail';
import InputField from 'fields/InputField';

import { AuthType, useAuth, useValidation } from 'hooks/useAuth';
import { useAppDispatch } from 'hooks/useRedux';
import { authTypeSelector, dataSelector, isSubmittingSelector } from 'store/signinInvitation/selectors';
import { setAuthType, setData } from 'store/signinInvitation/signinInvitationSlice';

import Visibility from 'assets/icons/Visibility';
import VisibilityOff from 'assets/icons/VisibilityOff';

interface LoginWithEmailProps {
  onSuccess?: (shouldRefreshContent?: boolean) => void;
}

const LoginWithEmail:FC<LoginWithEmailProps> = ({ onSuccess }) => {
  const intl = useIntl();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isSubmitting = useSelector(isSubmittingSelector);
  const authType = useSelector(authTypeSelector);
  const data = useSelector(dataSelector);
  const [showPassword, setShowPassword] = useState(false);
  const {
    checkEmail,
    signin,
    signup,
    errors,
    setErrors,
  } = useAuth(onSuccess);
  const { isValid } = useValidation();

  const disabled = useMemo(() => !isValid(data, authType), [data, authType]);

  const title = useMemo(() => {
    if (!authType) {
      return intl.formatMessage({ id: 'label.welcome' });
    }

    return authType === AuthType.Login
      ? intl.formatMessage({ id: 'label.welcomeBack' })
      : intl.formatMessage({ id: 'label.setupYourAccount' });
  }, [authType]);

  const handleChangeInput = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    dispatch(setData({ [name]: value }));

    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!isValid(data, authType)) {
      return;
    }

    if (!authType) {
      checkEmail(data.email);
    } else if (authType === AuthType.Login) {
      signin(data.email, data.password);
    } else {
      signup(data.email, data.password, data.name);
    }
  };

  const handleForgotPassword = () => dispatch(setAuthType(AuthType.ForgotPassword));

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  if (authType === AuthType.Verify) {
    return <VerifyEmail />;
  }

  if (authType === AuthType.ForgotPassword) {
    return <ForgotPassword emailAddress={data.email} />;
  }

  return (
    <form noValidate onSubmit={handleSubmit} autoComplete="off">
      <Navigation />

      <Typography variant="h1" textAlign="center" mb={4}>
        {title}
      </Typography>
      <InputField
        name="email"
        variant="outlined"
        size="medium"
        label={intl.formatMessage({ id: 'label.emailAddress' })}
        placeholder={intl.formatMessage({ id: 'placeholder.enterEmail' })}
        onChange={handleChangeInput}
        value={data.email || ''}
        fullWidth
        autoFocus
        error={!!errors.email}
        helperText={errors.email || ''}
        sx={{ mb: authType ? 2 : 0 }}
      />
      {authType === AuthType.Registration && (
        <InputField
          name="name"
          variant="outlined"
          size="medium"
          label={intl.formatMessage({ id: 'label.firstAndLastName' })}
          placeholder={intl.formatMessage({ id: 'placeholder.enterName' })}
          onChange={handleChangeInput}
          value={data.name || ''}
          error={!!errors.name}
          helperText={errors.name || ''}
          fullWidth
          autoFocus
          sx={{ mb: 2 }}
        />
      )}
      {authType && (
        <InputField
          name="password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          size="medium"
          label={intl.formatMessage({ id: 'label.password' })}
          placeholder={intl.formatMessage({ id: 'placeholder.enterPassword' })}
          onChange={handleChangeInput}
          value={data.password || ''}
          error={!!errors.password}
          helperText={errors.password || ''}
          fullWidth
          autoFocus={authType !== AuthType.Registration}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword
                      ? <Visibility />
                      : <VisibilityOff color={alpha(theme.palette.common.black, 0.3)} />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        className="big"
        fullWidth
        sx={{ my: 4 }}
        type="submit"
        disabled={isSubmitting || disabled}
      >
        {intl.formatMessage({ id: 'button.continue' })}
      </Button>

      {authType !== AuthType.Registration && (
        <Box display="flex" justifyContent="center">
          <UnderlineText
            color="textSecondary"
            variant="body2"
            textAlign="center"
            my={3}
            onClick={handleForgotPassword}
          >
            {intl.formatMessage({ id: 'label.forgotPassword' })}
          </UnderlineText>
        </Box>
      )}
    </form>
  );
};

export default LoginWithEmail;
