import { Dialog, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';

import { Paper } from 'components/StoryDialog/index.styled';

import useStoryDimensions from 'hooks/useStoryDimensions';

interface StoryDialogProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode | ReactNode[];
  sx?: SxProps;
}
const StoryDialog:FC<StoryDialogProps> = ({
  isOpen,
  onClose,
  children,
  sx,
}) => {
  const { width } = useStoryDimensions();
  const renderPaper = (props: any) => <Paper {...props} style={{ width: width - 56, margin: 0 }} sx={sx} />;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperComponent={renderPaper}
    >
      {children}
    </Dialog>
  );
};

export default StoryDialog;
