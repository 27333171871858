
export const getTime = (time: number) => {
  if (time < 60) {
    return {
      seconds: time < 10 ? `0${time}` : time.toString(),
      minutes: '00',
    };
  }

  const seconds = time % 60;
  const minutes = Math.trunc(time / 60);

  return {
    seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
    minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
  };
};

export const formatTimer = (time: number) => {
  const { minutes, seconds } = getTime(time);
  return `${minutes}:${seconds}`;
};

export const getPercentage = (time: number, counter: number) => {
  const oneUnitInPercentage = 100 / time;
  return oneUnitInPercentage * (time - counter);
};
