import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '~/store';

import { getUserEarnings } from 'services/User';
import { resetStore } from 'store/actions';

import { ApiStatus } from 'constants/enums';
import { Reward } from 'models/money.interface';
import { handleApiErrors } from 'utils/error';

export interface UserState {
  progress: {
    data: { reward: Reward|null };
    status: ApiStatus;
  };
}

export const initialState: UserState = {
  progress: {
    data: { reward: null },
    status: ApiStatus.idle
  },
};

export const fetchUserProgress = createAsyncThunk(
  'user/userProgress',
  async (userId: string, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const bountyId = state?.campaign?.campaignDetails?.id;

      if (!bountyId) {
        return thunkAPI.rejectWithValue(new Error('Campaign not found'));
      }

      const filters = new URLSearchParams();
      filters.set('bounty_id', bountyId);
      const { data } = await getUserEarnings(userId, filters);

      return { reward: data?.bountyEarnings?.[bountyId]?.earnedAmount };
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchUserProgress.pending, (state) => {
        state.progress.status = ApiStatus.loading;
      })
      .addCase(fetchUserProgress.fulfilled, (state, action) => {
        state.progress.data = action.payload;
        state.progress.status = ApiStatus.idle;
      })
      .addCase(fetchUserProgress.rejected, (state) => {
        state.progress.data.reward = null;
        state.progress.status = ApiStatus.idle;
      });
  },
});

export const {} = userSlice.actions;

export default userSlice.reducer;
