import { IconButton } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { Root } from 'containers/Authentication/Navigation/index.styled';

import { useAppDispatch } from 'hooks/useRedux';
import { authTypeSelector } from 'store/signinInvitation/selectors';
import { setAuthType, setLoginMethod } from 'store/signinInvitation/signinInvitationSlice';

import ArrowBack from 'assets/icons/ArrowBack';

const Navigation:FC = () => {
  const dispatch = useAppDispatch();
  const authType = useSelector(authTypeSelector);

  const handleBack = () => {
    if (!authType) {
      return dispatch(setLoginMethod(''));
    }

    return dispatch(setAuthType(''));
  };

  return (
    <Root>
      <IconButton onClick={handleBack}>
        <ArrowBack size={18} />
      </IconButton>
    </Root>
  );
};

export default Navigation;
