import { alpha, Box } from '@mui/material';
import MuiLinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export const Content = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.2),
  padding: theme.spacing(1),
  borderRadius: '4px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));

export const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
  borderRadius: '4px',
  height: '8px',
  backgroundColor: theme.palette.common.black,
  '& .MuiLinearProgress-bar': {
    backgroundColor: theme.palette.general.green,
    borderRadius: '4px',
  },
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: theme.palette.common.black,
}));
