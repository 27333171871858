import { alpha } from '@mui/material';
import MuiDivider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.black,
  borderRadius: '10px',
  padding: theme.spacing(2.5),
}));

export const Divider = styled(MuiDivider)(({ theme }) => ({
  borderColor: alpha(theme.palette.common.white, 0.3),
  margin: theme.spacing(2.5, 0),
}));
