import { PointCurrency } from 'constants/enums';
import { STARS_VALUE } from 'constants/rating';
import { Point, Reward } from 'models/money.interface';

export function sumOfAbs(points: Record<string, Point>) {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  let sum = 0;

  Object.values(points)
    .forEach(({ amount }) => {
      sum += Math.abs(amount);
    });

  return sum;
}

export function pointCurrencyAsEnum(currency: string): string {
  if (!currency) {
    return PointCurrency.None;
  }

  return PointCurrency[currency as keyof typeof PointCurrency] || PointCurrency.Unknown;
}

export function getNormalizedPoints(points: { [key: string]: Point }, currency: string) {
  if (!points || (points && !Object.keys(points).length)) {
    return 0;
  }

  return Object
    .values(points)
    .reduce((acc, point) => {
      const normPoint = normalizePoints(point);

      if (pointCurrencyAsEnum(normPoint.currency) === currency) {
        acc += normPoint.amount;
      }
      return acc;
    }, 0);
}

export function normalizeCurrency(currency: string): string {
  switch (currency) {
    case PointCurrency.Star2:
    case PointCurrency.Star3:
      return PointCurrency.Star;
    default:
      return currency;
  }
}

export function multiplier(currency: string): number {
  return STARS_VALUE[currency] || 1;
}

export function normalizePoints(point: Point) {
  const { amount, currency } = point;
  const pointCurrency = pointCurrencyAsEnum(currency);
  const norm = normalizeCurrency(pointCurrency);

  if (pointCurrency === norm) {
    return point;
  }

  return {
    currency: norm,
    amount: amount * multiplier(currency),
  };
}

export const getPointsAmount = (reward: Reward, currency: string) => {
  if (!reward) {
    return 0;
  }

  if (!reward?.points || !Object.keys(reward?.points).length) {
    return 0;
  }

  if (reward?.points[currency]?.amount) {
    return reward.points[currency].amount || 0;
  }

  // if systkn check for custom
  if (currency === PointCurrency.Systkn) {
    const currencyCode = Object.keys(reward.points)[0];

    if (!Object.keys(PointCurrency).includes(currencyCode)) {
      return reward.points[currencyCode].amount || 0;
    }

    return 0;
  }

  return 0;
};

export const isZeroPoints = (point: Point) => {
  const { amount } = point;

  return amount === 0;
};
