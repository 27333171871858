import { alpha, Button, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.common.black,
  borderRadius: '10px',
  padding: theme.spacing(2.5),
}));

export const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: '22px',
  padding: theme.spacing(1, 3, 1, 2),
  '& .MuiButton-startIcon': {
    marginRight: theme.spacing(0.5),
  },
}));

export const Content = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.2),
  color: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '10px',
  maxHeight: '100%',
  padding: theme.spacing(0, 0, 3),

  '& .MuiTypography-root': {
    color: theme.palette.common.white,
  },
}));

export const Separator = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(0, 4),
  borderColor: alpha(theme.palette.common.white, 0.3),
}));
