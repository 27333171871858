import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const CustomButton = styled(MuiButton)(({ theme }) => ({
  borderRadius: '10px',
  marginBottom: theme.spacing(2),
  fontSize: '20px',

  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
  },

  [theme.breakpoints.between(320, 380)]: {
    fontSize: '14px',
  },

  '& .MuiButton-startIcon': {
    position: 'absolute',
    left: '18px',
  },

  '& svg > path': {
    fill: 'currentColor',
  },
}));

export const GoogleButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,

  '&.Mui-disabled .MuiButton-icon': {
    opacity: 0.3,
  }
}));

export const AppleButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
}));
