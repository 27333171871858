import { alpha, Box, Button } from '@mui/material';
import CardMediaMui from '@mui/material/CardMedia';
import SkeletonMui from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

export const CardMedia = styled(CardMediaMui)(({ theme }) => ({
  width: '100%',
  objectFit: 'cover',
  borderRadius: '10px',
  height: '200px',

  [theme.breakpoints.down('sm')]: {
    minHeight: '200px',
    height: '100%',
  },
})) as typeof CardMediaMui;

export const RoundedButton = styled(Button)(({ theme }) => ({
  borderRadius: '22px',
  padding: theme.spacing(1, 3),
  width: 'fit-content',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1, 1.25),
  },
}));

export const ScheduledWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
}));

export const Skeleton = styled(SkeletonMui)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.common.white, 0.2),
}));
