import { alpha } from '@mui/material';

const MuiButton = {
  styleOverrides: {
    root: ({ theme: muiTheme }: any) => ({
      borderRadius: '8px',
      boxShadow: 'none',
      fontWeight: '700',
      fontSize: '15px',
      marginTop: 0,

      '&:hover': {
        boxShadow: 'none',
      },

      '&.big': {
        fontSize: '20px',
        minHeight: '51px',
      },

      '&.bigOutline': {
        fontSize: '20px',
        minHeight: '47px',
      },

      '&.MuiButton-outlinedPrimary': {
        borderColor: muiTheme.palette.primary.main
      },
    }),
    containedPrimary: ({ theme: muiTheme }: any) => ({
      '&.MuiButton-root:hover': {
        backgroundColor: muiTheme.palette.primary.dark,
      },

      '&.Mui-disabled': {
        opacity: 0.5,
        color: muiTheme.palette.common.white,
        backgroundColor: alpha(muiTheme.palette.primary.main, 0.5),
      },
    }),
    containedSecondary: ({ theme: muiTheme }: any) => ({
      backgroundColor: alpha(muiTheme.palette.common.white, 0.2),
      color: muiTheme.palette.common.white,
      fill: muiTheme.palette.common.white,

      '&.MuiButton-root:hover': {
        backgroundColor: alpha(muiTheme.palette.common.white, 0.33),
      },

      '&.Mui-disabled': {
        opacity: 0.5,
        color: muiTheme.palette.common.white,
        fill: muiTheme.palette.common.white,
        backgroundColor: alpha(muiTheme.palette.common.white, 0.33),
      },
    }),
    outlinedDark: ({ theme: muiTheme }: any) => ({
      border: `2px solid ${muiTheme.palette.primary.main}`,
      backgroundColor: alpha(muiTheme.palette.common.white, 0.2),
      color: muiTheme.palette.common.white,
      fill: muiTheme.palette.common.white,
      padding: muiTheme.spacing(1, 2),

      '&:hover': {
        backgroundColor: alpha(muiTheme.palette.common.white, 0.33),
      },

      '&.Mui-disabled': {
        opacity: 0.5,
        color: muiTheme.palette.common.white,
        backgroundColor: alpha(muiTheme.palette.common.white, 0.33),
      },
    }),
    outlinedLightPrimary: ({ theme: muiTheme }: any) => ({
      border: `2px solid ${muiTheme.palette.primary.main}`,
      color: alpha(muiTheme.palette.common.white, 0.7),
      backgroundColor: '#0062FF33',
      fontWeight: 400,

      '&.Mui-disabled': {
        opacity: 0.5,
        backgroundColor: '#0062FF33',
      },
    })
  },
};

export default MuiButton;
