/* eslint max-len: 0 */
import { ReactElement } from 'react';

import { CustomIcon } from 'models/icon.interface';

const GeneratingTokens = ({ color = 'current', size = 24 }: CustomIcon): ReactElement => (
  <svg id="generating_tokens_black_24dp_2_" data-name="generating_tokens_black_24dp (2)" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={'0 0 24 24'}>
    <rect id="Rectangle_78" data-name="Rectangle 78" width={size} height={size} fill="none" />
    <path fill={color} id="Path_105" data-name="Path 105" d="M9,4a8,8,0,1,0,8,8A8,8,0,0,0,9,4ZM9,15.5a1,1,0,0,1-1-1v-4H6.75a.75.75,0,0,1,0-1.5h4.5a.75.75,0,0,1,0,1.5H10v4A1,1,0,0,1,9,15.5ZM20.25,3.75,22,4.54a.5.5,0,0,1,0,.91l-1.75.79L19.46,8a.5.5,0,0,1-.91,0l-.79-1.75L16,5.46a.5.5,0,0,1,0-.91l1.75-.79L18.54,2a.5.5,0,0,1,.91,0Zm0,14,1.75.79a.5.5,0,0,1,0,.91l-1.75.79L19.46,22a.5.5,0,0,1-.91,0l-.79-1.75L16,19.46a.5.5,0,0,1,0-.91l1.75-.79L18.54,16a.5.5,0,0,1,.91,0Z" />
  </svg>
);

export default GeneratingTokens;
