import LoadingStorySkeleton from 'components/LoadingStory/index.skeleton';
import { Root } from 'components/LoadingStory/index.styled';

const LoadingStory = () => (
  <Root>
    <LoadingStorySkeleton />
  </Root>
);

export default LoadingStory;
