import { Button, Typography } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { ChangeEvent, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import Navigation from 'containers/Authentication/Navigation';
import InputField from 'fields/InputField';

import { useAppDispatch } from 'hooks/useRedux';
import { setAuthType } from 'store/signinInvitation/signinInvitationSlice';

import { auth } from 'configuration/firebase';
import { isEmailValid } from 'utils/error';

interface ForgotPasswordProps {
  emailAddress?: string;
}

const ForgotPassword:FC<ForgotPasswordProps> = ({ emailAddress }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState(emailAddress || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const disabled = !isEmailValid(email);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      setIsSubmitting(true);
      await sendPasswordResetEmail(auth, email);
      toast.success(intl.formatMessage({ id: 'label.forgotPasswordEmailSent' }));
      dispatch(setAuthType(''));
    } catch {
      toast.success(intl.formatMessage({ id: 'label.forgotPasswordEmailSent' }));
      dispatch(setAuthType(''));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChangeInput = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setEmail(value);
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Navigation />
      <Typography variant="h1" textAlign="center" mb={4}>
        {intl.formatMessage({ id: 'label.forgotPassword' })}
      </Typography>
      <Typography variant="body2" textAlign="center" mb={4}>
        {intl.formatMessage({ id: 'label.forgotPasswordDetails' })}
      </Typography>
      <InputField
        name="email"
        variant="outlined"
        size="medium"
        label={intl.formatMessage({ id: 'label.emailAddress' })}
        placeholder={intl.formatMessage({ id: 'placeholder.enterEmail' })}
        onChange={handleChangeInput}
        value={email || ''}
        fullWidth
      />
      <Button
        variant="contained"
        color="primary"
        className="big"
        fullWidth
        type="submit"
        sx={{ my: 4 }}
        disabled={isSubmitting || disabled}
      >
        {intl.formatMessage({ id: 'button.continue' })}
      </Button>
    </form>
  );
};

export default ForgotPassword;
