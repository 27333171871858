import { Typography } from '@mui/material';
import { FC } from 'react';

import Loader from 'components/Loader';
import StoryDialog from 'components/StoryDialog';

import { CardMedia } from './index.styled';

interface MessageDialogProps {
  onClose: () => void;
  title?: string;
  subtitle?: string;
  src?: string;
  linkMessage?: string;
  onClick?: () => void;
  isOpen?: boolean;
  isLoading?: boolean;
}

const MessageDialog: FC<MessageDialogProps> = ({
  title,
  subtitle,
  src,
  linkMessage,
  isOpen = false,
  isLoading = false,
  onClose,
  onClick,
}) => {
  const hasMb = subtitle || linkMessage;

  return (
    <StoryDialog
      isOpen={isOpen}
      onClose={onClose}
    >
      {isLoading
        ? <Loader />
        : (
          <>
            {src && <CardMedia src={src} component="img" alt={src} />}

            {title && (
              <Typography variant="h2" mb={hasMb ? 2 : 0} textAlign="center">
                {title}
              </Typography>
            )}

            {subtitle && (
              <Typography variant="subtitle2" fontWeight={700} textAlign="center" mb={0.5}>
                {subtitle}
              </Typography>
            )}

            {linkMessage && (
              <Typography
                variant="h2"
                fontWeight={700}
                color="primary.main"
                textAlign="center"
                sx={{ cursor: 'pointer' }}
                onClick={onClick}
              >
                {linkMessage}
              </Typography>
            )}
          </>
        )}
    </StoryDialog>
  );
};

export default MessageDialog;
