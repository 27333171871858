import { Box, Typography, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoadingStory from 'components/LoadingStory';
import CampaignDetails from 'containers/SeriesDetails/CampaignDetails';
import { Root, Divider } from 'containers/SeriesDetails/index.styled';
import SigninInvitation from 'containers/SigninInvitation';
import { FbUserContext } from 'context/FbUserContext';

import { getBountyActivitiesChain } from 'services/Bounty';
import { getUserEarnings } from 'services/User';

import ArrowForward from 'assets/icons/ArrowForward';
import { SERIES_RANKINGS_ROUTE } from 'constants/clientRoutes';
import { Bounty } from 'models/bounty.interface';
import { Earnings } from 'models/earnings.interface';
import { formatRoute } from 'utils/formatters';

const SeriesDetails = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { campaignId = '' } = useParams();
  const { fbUser } = useContext(FbUserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEarnings, setIsLoadingEarnings] = useState(false);
  const [campaigns, setCampaigns] = useState<Bounty[]>([]);
  const [earnings, setEarnings] = useState<Record<string, Earnings>>({});
  
  useEffect(() => {
    if (campaignId) {
      fetchActivitiesChain();
    }
  }, [campaignId]);

  useEffect(() => {
    if (fbUser?.uid && campaigns?.length > 0) {
      fetchEarnings();
    }
  }, [fbUser?.uid, campaigns]);

  const fetchActivitiesChain = async () => {
    setIsLoading(true);

    try {
      const response = await getBountyActivitiesChain(campaignId);
      setCampaigns(response?.data?.list || []);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEarnings = async () => {
    if (!fbUser?.uid) {
      toast.error(intl.formatMessage({ id: 'error.missingUserId' }));
      return;
    }

    setIsLoadingEarnings(true);

    try {
      const bountyIds = campaigns?.map(({ id }) => id);
      const filters = new URLSearchParams();
      filters.set('bounty_id', bountyIds?.join(','));

      const response = await getUserEarnings(fbUser?.uid, filters);

      setEarnings(response?.data?.bountyEarnings);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    } finally {
      setIsLoadingEarnings(false);
    }
  };

  const handleSeeRankings = () => {
    navigate(formatRoute(SERIES_RANKINGS_ROUTE, { campaignId }));
  };

  if (isLoading || !campaigns?.length) {
    return <LoadingStory />;
  }

  return (
    <Root className="hidden-scroll">
      {campaigns?.map((campaign) => (
        <Box key={campaign?.id}>
          <CampaignDetails
            bounty={campaign}
            earnings={earnings?.[campaign?.id] || null}
            isLoadingEarnings={isLoadingEarnings}
          />
          <Divider sx={{ my: 2.5 }} />
        </Box>
      ))}
      <Button
        variant="outlinedDark"
        onClick={handleSeeRankings}
        endIcon={<ArrowForward />}
        fullWidth
        sx={{ p: 2 }}
      >
        <Typography variant="subtitle" fontWeight={600}>
          {intl.formatMessage({ id: 'button.seeRankings' })}
        </Typography>
      </Button>
      <SigninInvitation />
    </Root>
  );
};

export default SeriesDetails;
