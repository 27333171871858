import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import Logo from 'components/Logo';
import { UnderlineText } from 'containers/Authentication/index.styled';
import {
  AppleButton,
  CustomButton,
  GoogleButton,
} from 'containers/Authentication/LoginMethods/index.styled';

import Apple from 'assets/icons/Apple';
import Google from 'assets/icons/Google';
import Mail from 'assets/icons/Mail';
import flavourConfig from 'configuration/env';
import { LoginMethod } from 'constants/enums';

interface LoginMethodsProps {
  chooseLoginMethod: (method: LoginMethod) => void;
  isSubmitting: boolean;
}

const LoginMethods:FC<LoginMethodsProps> = ({ chooseLoginMethod, isSubmitting }) => {
  const intl = useIntl();

  const renderNewLine = () => <br />;

  const handleGoToTos = () => {
    window.open(flavourConfig.tosUrl, '_blank');
  };

  const handleGoToPrivacy = () => {
    window.open(flavourConfig.privacyUrl, '_blank');
  };

  const renderTosChunks = (chunks: ReactNode[]) => (
    <UnderlineText variant="body2" onClick={handleGoToTos}>
      {chunks}
    </UnderlineText>
  );

  const renderPrivacyChunks = (chunks: ReactNode[]) => (
    <UnderlineText variant="body2" onClick={handleGoToPrivacy}>
      {chunks}
    </UnderlineText>
  );

  return (
    <Box px="2px">
      <Box display="flex" flexDirection="column" alignItems="center" mb={5}>
        <Logo isDark />
      </Box>

      <CustomButton
        fullWidth
        variant="contained"
        color="primary"
        startIcon={<Mail />}
        onClick={() => chooseLoginMethod(LoginMethod.Email)}
        disabled={isSubmitting}
      >
        {intl.formatMessage({ id: 'button.continueWithEmail' })}
      </CustomButton>
      <GoogleButton
        fullWidth
        startIcon={<Google />}
        onClick={() => chooseLoginMethod(LoginMethod.Google)}
        disabled={isSubmitting}
      >
        {intl.formatMessage({ id: 'button.continueWithGoogle' })}
      </GoogleButton>
      <AppleButton
        fullWidth
        variant="contained"
        startIcon={<Apple />}
        onClick={() => chooseLoginMethod(LoginMethod.Apple)}
        disabled={isSubmitting}
      >
        {intl.formatMessage({ id: 'button.continueWithApple' })}
      </AppleButton>

      <Box display="flex" justifyContent="center">
        <Typography
          variant="body2"
          textAlign="center"
          my={3}
        >
          {intl.formatMessage(
            { id: 'label.tos' },
            {
              br: renderNewLine,
              tos: renderTosChunks,
              privacy: renderPrivacyChunks,
            },
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginMethods;
