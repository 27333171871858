import { Box, Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import Copy from 'assets/icons/Copy';
import Launch from 'assets/icons/Launch';
import { isAndroid } from 'utils/env';
import { getAndroidIntentUrl } from 'utils/links';

const ExternalBrowser = () => {
  const intl = useIntl();
  const isUsingAndroid = isAndroid();

  const openInAndroid = () => {
    try {
      window.location.href = getAndroidIntentUrl();
    } catch (error) {
      console.error('ALERT: ', JSON.stringify(error));
    }
  };

  const openApp = async () => {
    if (isUsingAndroid) {
      openInAndroid();
      return;
    }

    try {
      await navigator.clipboard.writeText(window.location.href.split('?')[0]);
      toast.success(intl.formatMessage({ id: 'label.copiedToClipboard' }));
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  return (
    <div>
      <Typography variant="subtitle2" textAlign="center" gutterBottom>
        {intl.formatMessage({ id: 'label.continueInBrowser' })}
      </Typography>

      {isUsingAndroid
        ? (
          <Typography variant="subtitle2" fontWeight={400} textAlign="center" mb={4}>
            {intl.formatMessage({ id: 'label.progressMayResetAndroid' })}
          </Typography>
        ) : (
          <Box mb={4}>
            <Typography variant="subtitle2" fontWeight={400} textAlign="center" gutterBottom>
              {intl.formatMessage({ id: 'label.tapDotsMenu' })}
            </Typography>
            <Typography variant="subtitle2" fontWeight={400} textAlign="center">
              {intl.formatMessage({ id: 'label.progressMayResetIOS' })}
            </Typography>
          </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        className="big"
        fullWidth
        onClick={openApp}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {intl.formatMessage({ id: isUsingAndroid ? 'button.openInBrowser' : 'button.copyAddress' })}
          {isUsingAndroid ? <Launch color="white" /> : <Copy color="white" />}
        </Box>
      </Button>
    </div>
  );
};

export default ExternalBrowser;
