import { Reward } from 'models/money.interface';

export const getPercentage = (currentValue?: number, totalValue?: number): number => {
  if (!totalValue || !currentValue || totalValue === 0 || currentValue === 0) {
    return 0;
  }

  return Math.round((currentValue * 100.0) / totalValue);
};

export const getPointsAmountFromReward = (reward?: Reward) => {
  if (!reward?.points) {
    return 0;
  }

  return Object.values(reward?.points)?.map(({ amount }) => amount)?.[0];
};
