import {
  Box, IconButton, Skeleton, Typography,
} from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import ReplyIcon from 'assets/icons/ReplyIcon';

interface EmptyLeaderboardProps {
  onClick: () => void;
}

export const EmptyLeaderboard: FC<EmptyLeaderboardProps> = ({ onClick }) => {
  const intl = useIntl();

  return (
    <>
      <Box display="flex" justifyContent="center" mb={1}>
        <IconButton onClick={onClick}>
          <ReplyIcon />
        </IconButton>
      </Box>

      <Typography variant="h2" textAlign="center" fontWeight={400}>
        {intl.formatMessage({ id: 'label.stayTunedForUpcomingRankings' })}
      </Typography>

      <Box display="flex" alignItems="center" gap={1} mt={3}>
        <Skeleton variant="circular" width="30px" height="30px" animation="wave" />
        <Skeleton variant="rounded" width="calc(100% - 30px)" height="30px" animation="wave" />
      </Box>

      <Box display="flex" alignItems="center" gap={1} mt={3}>
        <Skeleton variant="circular" width="30px" height="30px" animation="wave" />
        <Skeleton variant="rounded" width="calc(100% - 30px)" height="30px" animation="wave" />
      </Box>

      <Box display="flex" alignItems="center" gap={1} mt={3}>
        <Skeleton variant="circular" width="30px" height="30px" animation="wave" />
        <Skeleton variant="rounded" width="calc(100% - 30px)" height="30px" animation="wave" />
      </Box>
    </>
  );
};

export default EmptyLeaderboard;
