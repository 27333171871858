import { Typography, Grid2 as Grid, Box } from '@mui/material';
import { FC, useContext } from 'react';
import { useIntl } from 'react-intl';

import UserAvatar from 'components/UserAvatar';
import UserName from 'components/UserName';
import { formatTimer } from 'containers/BountyTrivia/Header/utils';
import { LeaderboardContext } from 'context/LeaderboardContext';

import { useAppDispatch } from 'hooks/useRedux';
import { openModalSignIn } from 'store/signinInvitation/signinInvitationSlice';

import GeneratingTokens from 'assets/icons/GeneratingTokens';
import PersonOutline from 'assets/icons/PersonOutline';
import { UserPosition } from 'models/leaderboard.interface';
import { hashStringToColor } from 'utils/colors';
import { formatReward } from 'utils/reward';
import { getUsernameInitials } from 'utils/user';

import {
  DetailsWrapper,
  IconWrapper,
  Root,
} from './index.styled';

interface ParticipantRankProps {
  userPosition: UserPosition;
  index: number;
  isActive?: boolean;
}

const ParticipantRank: FC<ParticipantRankProps> = ({
  userPosition,
  index,
  isActive = false,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { user, reward } = userPosition;
  const isActiveAndAnon = !user?.name && isActive;
  const { counter } = useContext(LeaderboardContext);

  const handleRegister = async () => {
    if (isActiveAndAnon) {
      dispatch(openModalSignIn({ claimTokens: true }));
    }
  };

  return (
    <Root
      isActive={isActive}
      isClaim={isActiveAndAnon}
      onClick={handleRegister}
    >
      <DetailsWrapper avatarColor={hashStringToColor(user?.name)}>
        <Grid container alignItems="center">
          <Grid size={1.5}>
            <Typography variant="subtitle1" minWidth="30px" textAlign="center">
              {`${index}.`}
            </Typography>
          </Grid>

          <Grid size={{ xs: 10.5, sm: 7.5 }}>
            <Box display="flex" alignItems="center" gap={1}>
              {isActiveAndAnon ? (
                <IconWrapper>
                  <GeneratingTokens />
                </IconWrapper>
              ) : (
                <UserAvatar
                  src={user?.avatar}
                  height={40}
                  width={40}
                  userInitials={getUsernameInitials(user.name)}
                  defaultIcon={!user?.name && <PersonOutline color="white" />}
                />
              )}
              <Box className="text-ellipsis">
                {isActiveAndAnon ? (
                  <Typography
                    variant="subtitle1"
                    fontWeight={700}
                    color={isActiveAndAnon ? 'common.white' : 'primary.main'}
                    className="text-ellipsis"
                  >
                    {`${intl.formatMessage({ id: 'button.registerToClaim' })}!`}
                    <br />
                    {formatTimer(counter)}
                  </Typography>
                ) : (
                  <UserName
                    name={user.name || intl.formatMessage({ id: 'label.youAreAlmostHere' })}
                    variant="subtitle1"
                    fontWeight={700}
                    className={`userName text-ellipsis ${!user?.name && 'anon'}`}
                  />
                )}
                <Typography variant="subtitle1" className="tokens">
                  {formatReward(reward)}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }} className="inlineTokens">
            <Typography variant="subtitle1" textAlign="right">
              {formatReward(reward)}
            </Typography>
          </Grid>
        </Grid>
      </DetailsWrapper>
    </Root>
  );
};

export default ParticipantRank;
