import { capitalize } from 'lodash';

const CUSTOM_PREFIX = 'rep:';
const ICON_PREFIX = 'gmd_';

export const getUsernameInitials = (name?: string): string => {
  if (!name) {
    return 'UN';
  }

  const [firstName, lastName] = name.split('');
  return firstName?.[0] || lastName?.[0];
};

export const reputationBuilder = (reputation?: string) => {
  if (!reputation) return {};

  const parts = (reputation
      && reputation.replace('code=rep:', '').substring(CUSTOM_PREFIX.length).split('&').filter((item) => item))
    || [];
  const reputationProps: Record<string, string> = {};

  parts.forEach((part) => {
    const [name, value] = part.split('=').filter((item) => item);
    reputationProps[name] = value;
  });

  if (reputationProps.icon && reputationProps.icon.startsWith(ICON_PREFIX)) {
    reputationProps.icon = reputationProps.icon.substring(ICON_PREFIX.length);
  }

  return reputationProps;
};

export const extractNameFromEmail = (email: string) => {
  const [namePart] = email.split('@');
  const formattedName = namePart.replace(/[._-]/g, ' ');

  return formattedName
    .split(' ')
    .map(capitalize)
    .join(' ');
};
