import { BountyType, CompanySentsFolder, ConfirmationType } from 'constants/enums';

export const SENTS_FOLDER_BY_TYPE: Record<string, string> = {
  [BountyType.Product]: CompanySentsFolder.Products,
  [BountyType.BadgeCollection]: CompanySentsFolder.BadgeCollections,
  [BountyType.Promotion]: CompanySentsFolder.Promotions,
  [BountyType.Job]: CompanySentsFolder.Jobs,
  [BountyType.Autosuggestion]: CompanySentsFolder.Autosuggestion,
  [BountyType.PrivateListing]: CompanySentsFolder.Listings,
  [BountyType.Order]: CompanySentsFolder.Orders,
  [BountyType.News]: CompanySentsFolder.News,
  [BountyType.Banner]: CompanySentsFolder.Banners,
  [BountyType.Match]: CompanySentsFolder.Matches,
};

export const HINT_TYPE_BY_BOUNTY_TYPE: Record<string, string> = {
  [BountyType.Campaign]: ConfirmationType.HideHowItWorksCampaignHint,
  [BountyType.Survey]: ConfirmationType.HideHowItWorksPollHint,
  [BountyType.MCQ]: ConfirmationType.HideHowItWorksQuizHint,
  [BountyType.Trivia]: ConfirmationType.HideHowItWorksTriviaHint,
  [BountyType.TMOB_Challenge]: ConfirmationType.HideHowItWorksChallengeHint,
  [BountyType.Lottery]: ConfirmationType.HideHowItWorksLotteryHint,
  [BountyType.Funding]: ConfirmationType.HideHowItWorksFundingHint,
};
