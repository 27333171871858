import { Money } from 'models/money.interface';

export const isZero = (money: Money) => !money.amount || money.amount === '0' || money.amount === '0.0';

export const getAmountAsBigDecimal = (amount: string, enforceDecimal?: boolean) => {
  if (!amount || amount === '0' || amount === '0.0') {
    return 0;
  }

  try {
    const floatAmount = Number.parseFloat(amount);

    if (enforceDecimal) {
      return floatAmount.toFixed(2);
    }

    return (
      Number.isInteger(floatAmount)
        ? floatAmount.toString()
        : floatAmount.toFixed(2)
    );
  } catch {
    return 0;
  }
};
