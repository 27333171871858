import { Typography } from '@mui/material';
import { FC } from 'react';

import { CustomTypographyVariants } from 'assets/theme/theme-declarations';

interface UserNameProps {
  name: string;
  variant?: CustomTypographyVariants;
  fontWeight?: number;
  className?: string;
}

const UserName: FC<UserNameProps> = ({
  name,
  variant = 'body1',
  fontWeight = 400,
  className = '',
}) => (
  <Typography variant={variant} fontWeight={fontWeight} className={className}>
    {name}
  </Typography>
);

export default UserName;
