import { Box, Grid2 as Grid, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  CardMedia,
  RoundedButton,
  ScheduledWrapper,
  Skeleton
} from 'containers/SeriesDetails/CampaignDetails/index.styled';
import TokensEarned from 'containers/SeriesDetails/TokensEarned';
import { getPointsAmountFromReward } from 'containers/SeriesDetails/utils';
import { FbUserContext } from 'context/FbUserContext';

import { useAppDispatch } from 'hooks/useRedux';
import { setNotifyMe } from 'services/Bounty';
import { openModalSignIn } from 'store/signinInvitation/signinInvitationSlice';

import NotificationAdd from 'assets/icons/NotificationAdd';
import DefaultCover from 'assets/images/bkg-img.png';
import { CAMPAIGN_VIEW } from 'constants/clientRoutes';
import { DateFormats } from 'constants/enums';
import { Bounty } from 'models/bounty.interface';
import { CampaignDescription } from 'models/description.interface';
import { Earnings } from 'models/earnings.interface';
import { handleApiErrors } from 'utils/error';
import { formatDate, formatRoute } from 'utils/formatters';
import { parseEncryptedDescription } from 'utils/yaml';

interface CampaignDetailsProps {
  bounty: Bounty;
  earnings?: Earnings | null;
  isLoadingEarnings?: boolean;
}

const CampaignDetails: FC<CampaignDetailsProps> = ({
  bounty,
  earnings,
  isLoadingEarnings = false,
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { fbUser } = useContext(FbUserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toBeNotified, setToBeNotified] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const coverUrl = bounty?.attachments?.attachments
    ? Object.values(bounty?.attachments?.attachments)?.[0]?.url
    : DefaultCover;
  const description = bounty?.description
    ? parseEncryptedDescription<CampaignDescription>(bounty?.description)
    : {};
  const isScheduled = useMemo(
    () => !!(description?.startAt && description?.startAt > new Date().getTime()),
    [description?.startAt],
  );
  const notifyOnStart = description?.notifyOnStart || toBeNotified;
  const earnedAmount = getPointsAmountFromReward(earnings?.earnedAmount);
  const maxAmount = getPointsAmountFromReward(earnings?.maxAmount);

  const handleOpenCampaign = () => {
    navigate(formatRoute(CAMPAIGN_VIEW, { campaignId: bounty?.shortId }));
  };

  const handleNotifyMe = async () => {
    if (!fbUser || fbUser?.isAnonymous) {
      dispatch(openModalSignIn());
      return;
    }

    if (!bounty?.id) {
      toast.error(intl.formatMessage({ id: 'error.cannotSetNotifyMe' }));
      return;
    }

    setIsSubmitting(true);
    try {
      await setNotifyMe(bounty?.id);
      setToBeNotified(true);
      toast.success(intl.formatMessage({ id: 'label.sendYouAnAlertWhen' }));
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderScheduledDate = (startAt: number) => (
    <Typography variant="body2" color="general.lightGrey3">
      {intl.formatMessage({ id: 'label.scheduled' })}:
      <br />
      {formatDate(startAt, DateFormats.fullDateFormat)}
    </Typography>
  );

  return (
    <Grid container columnSpacing={{ xs: 1.5, sm: 2.5 }}>
      <Grid size={{ xs: 5, sm: 3 }} sx={{ cursor: 'pointer' }} onClick={handleOpenCampaign}>
        <CardMedia component="img" alt="campaign cover" src={coverUrl} />
      </Grid>
      <Grid size={{ xs: 7, sm: 9 }}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
          <Box mb={2.5}>
            {bounty.title && (
              <Typography
                variant="h2"
                color="common.white"
                mb={2.5}
                onClick={handleOpenCampaign}
                sx={{ cursor: 'pointer' }}
              >
                {bounty.title}
              </Typography>
            )}
            {isLoadingEarnings
              ? <Skeleton variant="rounded" animation="wave" width="100%" height="40px" />
              : maxAmount > 0 && <TokensEarned earnedAmount={earnedAmount} maxAmount={maxAmount} />}
          </Box>
          {isScheduled ? (
            <ScheduledWrapper>
              {isMobile && description?.startAt && renderScheduledDate(description?.startAt)}
              <RoundedButton variant="outlinedDark" onClick={handleNotifyMe} disabled={isSubmitting || notifyOnStart}>
                <Typography variant="body2" sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                  <Box display="inline-block" pt="1px">
                    {intl.formatMessage({ id: notifyOnStart ? 'label.notificationSet' : 'label.notifyMe' })}
                  </Box>
                  <NotificationAdd color="white" />
                </Typography>
              </RoundedButton>
              {!isMobile && description?.startAt && renderScheduledDate(description?.startAt)}
            </ScheduledWrapper>
          ) : (
            <RoundedButton variant="contained" color="primary" onClick={handleOpenCampaign}>
              <Typography variant="h2">
                {intl.formatMessage({ id: 'button.open' })}
              </Typography>
            </RoundedButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default CampaignDetails;
