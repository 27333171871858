import { styled } from '@mui/material/styles';

export const Root = styled('div')<{ isActive: boolean; isClaim: boolean }>(({ theme, isActive, isClaim }) => ({
  padding: theme.spacing(2),
  margin: theme.spacing(1.5, 1),
  borderRadius: '8px',
  cursor: isClaim ? 'pointer' : 'auto',
  // eslint-disable-next-line no-nested-ternary
  backgroundColor: isActive
    ? (isClaim ? theme.palette.primary.main : theme.palette.general.darkGrey)
    : (isClaim ? theme.palette.primary.main : 'initial'),
  color: isClaim ? theme.palette.common.white : 'initial',

  [theme.breakpoints.only('xs')]: {
    padding: theme.spacing(1, 0.5),
  },
}));

export const DetailsWrapper = styled('div')<{ avatarColor: string }>(({ theme, avatarColor }) => ({
  '& .userName': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  '& .anon': {
    fontStyle: 'italic',
  },

  '& .MuiAvatar-root': {
    backgroundColor: avatarColor,
    color: theme.palette.common.white,
  },

  '& .tokens': {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  '& .inlineTokens': {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  height: '40px',
  width: '40px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,
  '& path': {
    fill: theme.palette.primary.main,
  },
  minWidth: '40px',
}));

export const Content = styled('div')<{ width: number }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  gap: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    width: '100%',
    alignItems: 'flex-start',
  },
}));
