import { Box, Button, Typography } from '@mui/material';
import { sendEmailVerification, AuthErrorCodes } from 'firebase/auth';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import StoryDialog from 'components/StoryDialog';
import { UnderlineText } from 'containers/Authentication/index.styled';

import { AuthType } from 'hooks/useAuth';
import { useAppDispatch } from 'hooks/useRedux';
import { tempUserSelector } from 'store/signinInvitation/selectors';
import { setAuthType, setTempUser } from 'store/signinInvitation/signinInvitationSlice';

const VerifyEmail = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const tempUser = useSelector(tempUserSelector);
  const [isWarningOpen, setIsWarningOpen] = useState(false);

  const showEmailFormAgain = () => {
    dispatch(setAuthType(AuthType.Login));
    dispatch(setTempUser(undefined));
  };

  const resendEmailVerification = async () => {
    try {
      if (tempUser) {
        await sendEmailVerification(tempUser);
        toast.success(intl.formatMessage({ id: 'label.verificationSent' }));
      }
    } catch (e: any) {
      if (e.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
        setIsWarningOpen(true);
      }
    }
  };

  const renderSpanChunks = (chunks: ReactNode[]) => (
    <UnderlineText variant="body3" onClick={resendEmailVerification}>
      {chunks}
    </UnderlineText>
  );

  const renderBr = () => <br />;

  return (
    <div>
      <Typography variant="h1" textAlign="center" mb={4}>
        {intl.formatMessage({ id: 'label.almostThereConfetti' })}
      </Typography>
      <Typography variant="h2" textAlign="center" mb={4}>
        {intl.formatMessage({ id: 'label.confirmYourEmail' })}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        className="big"
        fullWidth
        onClick={showEmailFormAgain}
      >
        {intl.formatMessage({ id: 'button.continue' })}
      </Button>

      <Typography
        variant="body3"
        textAlign="center"
        display="inline-block"
        width="100%"
        my={3}
      >
        {intl.formatMessage(
          { id: 'label.didntReceiveTheEmail' },
          {
            span: renderSpanChunks,
            br: renderBr,
          },
        )}
      </Typography>

      <StoryDialog
        isOpen={isWarningOpen}
        onClose={() => setIsWarningOpen(false)}
      >
        <Box>
          <Typography variant="h1" mb={4}>
            {intl.formatMessage({ id: 'label.headsUp' })}
          </Typography>
          <Typography>
            {intl.formatMessage({ id: 'label.tooManyResentVerificationRequests' })}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            className="big"
            fullWidth
            sx={{ my: 4 }}
            onClick={() => setIsWarningOpen(false)}
          >
            {intl.formatMessage({ id: 'button.ok' })}
          </Button>
        </Box>
      </StoryDialog>
    </div>
  );
};

export default VerifyEmail;
