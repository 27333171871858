import { LEADERBOARD_COLORS } from 'constants/colors';

export const hashStringToColor = (name: string): string => {
  let hash = 0;

  for (let i = 0; i < name?.length; i += 1) {
    const char = name.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + char;
    // eslint-disable-next-line no-bitwise
    hash &= hash; // Convert to 32bit integer
  }

  const colorIndex = Math.abs(hash) % LEADERBOARD_COLORS.length;
  return LEADERBOARD_COLORS[colorIndex];
};
