import { Box, Typography, useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { Content, IconWrapper, LinearProgress } from 'containers/SeriesDetails/TokensEarned/index.styled';
import { getPercentage } from 'containers/SeriesDetails/utils';

import GeneratingTokens from 'assets/icons/GeneratingTokens';

interface TokensEarnedProps {
  earnedAmount: number;
  maxAmount: number;
}

const TokensEarned: FC<TokensEarnedProps> = ({ earnedAmount, maxAmount }) => {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const labelValue = earnedAmount
    ? <b>{earnedAmount?.toLocaleString()} / {maxAmount?.toLocaleString()}</b>
    : (
      <Typography component="span" textTransform="uppercase">
        {intl.formatMessage({ id: 'label.none' })}
      </Typography>
    );
  const percentage = getPercentage(earnedAmount, maxAmount);

  return (
    <>
      <Content mb={earnedAmount ? 0.25 : 0}>
        <Box display="flex" alignItems="center" gap={1}>
          <IconWrapper>
            <GeneratingTokens color="white" size={18} />
          </IconWrapper>
          <Typography variant="body2" color={earnedAmount ? 'common.white' : 'general.lightGrey3'}>
            {isMobile && earnedAmount
              ? labelValue
              : intl.formatMessage({ id: 'label.tokensEarnedValue' }, { value: labelValue })}
          </Typography>
        </Box>
        {!!earnedAmount && !!maxAmount && (
          <Typography variant="body2" color="general.lightGrey3">
            {`${percentage}%`}
          </Typography>
        )}
      </Content>
      {!!earnedAmount && <LinearProgress variant="determinate" value={percentage} color="success" />}
    </>
  );
};

export default TokensEarned;
