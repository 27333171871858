import { alpha } from '@mui/material';

const palette = {
  primary: {
    main: '#0062FF',
    secondary: '#0062FF',
    dark: '#004ecc',
  },
  secondary: {
    main: '#E5EFFE',
    secondary: '#E5EFFE',
    dark: '#E5EFFE',
  },
  error: {
    main: '#FF3C3C',
    light: '#FF4C4E',
  },
  success: {
    main: '#08C043',
    light: '#07bc0c',
  },
  warning: {
    main: '#FFA800',
    light: '#FFA800',
  },
  background: {
    main: '#171717',
    light: '#171717',
  },
  text: {
    primary: '#000',
    secondary: alpha('#000', 0.5),
  },
  general: {
    reddishBrown: '#8B7355',
    borderColor: alpha('#000', 0.2),
    fieldBorder: '#0000003b',
    backgroundGrey: '#ccc',
    lightGrey: '#d7d7d7',
    lightGrey2: '#FFFFFF4D',
    lightGrey3: '#a2a2a2',
    lightGrey4: '#0000004D',
    lightGrey5: '#e7e7e7',
    lightGrey6: '#42413f',
    darkGrey: '#0000001A',
    darkGrey2: '#7c7c7c',
    purple: '#A639E0',
    green: '#2AAC61',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
};

export default palette;
